import { Icon, Heading, Flex } from '@aws-amplify/ui-react';

export const Logo = (config?: { size?: { width: number; height: number }; title?: string }) => {
  return (
    <Flex display={{ base: 'none', small: 'flex' }} alignItems={'center'}>
      <Icon
        display={{ base: 'none', small: 'flex' }}
        pathData="M70.67,39.37c2,.31,2.4-.1,2.67-2a45.17,45.17,0,0,1,3.89-12.75C79.85,19.24,84,13.33,89.85,9.36a62.78,62.78,0,0,1,19.2-8.26,46.83,46.83,0,0,1,14-.93c8.42.76,13.08,3.16,15.42,6.27A6.75,6.75,0,0,1,139.81,10c.07,1.55-.6,3.56-2.83,5.54s-6.77,4.33-13.06,6.44c-6.52,2.17-15.44,4.47-24.33,6.51a137.22,137.22,0,0,0-15.22,4.37c-5.88,2-7.65,7.85-4,9.64A54.92,54.92,0,0,1,91.82,50,101.32,101.32,0,0,1,107.08,65c5.53,6.76,14.59,19.66,17.84,32.18,3.61,13.75,1.36,26.78-6.42,30.42-7.63,3.58-17.79-1.58-24.93-9-6.78-7-11.52-15.29-16-28-3.86-11-5.42-26.87-5.42-35.19,0-2.77,0-3.36.07-5.86.26-2.18-5.62-4-11.94.08-7.19,4.63-14.23,13-18.39,17.88-1.81,2.13-4.27,5.25-6.86,8.52-3.43,4.3-7.21,8.78-10.66,11.28C19.2,91,10.87,92.6,5,88.44c-3.25-2.32-5-6.7-5-11.16a16.91,16.91,0,0,1,2.3-8.77c2-3.43,5.17-7.12,10.28-11.34A90.51,90.51,0,0,1,34.73,44.54c12.88-5.19,26.75-6.82,35.94-5.17Z"
        viewBox={{
          width: 139.82,
          height: 128.73,
        }}
        width={config?.size?.width ?? 32}
        height={config?.size?.height ?? 32}
        color="01acfb"
        ariaLabel="Telenor Logo"
      />
      <Flex display={{ base: 'none', medium: 'flex' }}>
        <Heading level={5}>{config?.title}</Heading>
      </Flex>
    </Flex>
  );
};
