import { Flex, Heading, Placeholder, Text } from '@aws-amplify/ui-react';
import { AuthUser, FetchUserAttributesOutput } from '@aws-amplify/auth';

const HomePage: React.FC<{ authUser?: AuthUser; attributes?: FetchUserAttributesOutput }> = ({
  authUser,
  attributes,
}) => {
  const text = authUser
    ? 'Use the menus above to manage, here will come a dashboard'
    : 'Log in to manage, or go straight to test config to activate test config on your device.';
  return (
    <Flex direction={'column'}>
      {authUser ? <Heading level={3}>{`Hello ${attributes?.email ?? '👻'}!`}</Heading> : <Placeholder size="large" />}
      <Text>{text}</Text>
    </Flex>
  );
};

export default HomePage;
