import {
  Divider,
  Flex,
  Heading,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withAuthenticator,
} from '@aws-amplify/ui-react';

const ToolsPage = () => {
  return (
    <Flex direction="column">
      <Heading level={4}>{'Here are some tools that will help with development'}</Heading>
      <Divider size="small" />
      <Heading level={5}>{'⛓️ Deep Links Testing'}</Heading>
      <Table caption="Note: Not a full list, will be automated soon" highlightOnHover={true}>
        <TableHead>
          <TableRow>
            <TableCell as="th">URL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {deepLinks.map((aLink) => {
            return (
              <TableRow>
                <TableCell>
                  <Link href={aLink}>{aLink}</Link>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Flex>
  );
};

const deepLinks = [
  'https://www.telenor.no/mine-tilbud',
  'https://www.telenor.no/mitt-telenor/tv-internett',
  'https://www.telenor.no/mitt-telenor',
  'https://www.telenor.no/a/data-purchase/{msisdn}',
  'https://www.telenor.no/a/safe-bundle-hero',
  'https://www.telenor.no/a/safe-surf-warning-page',
  'https://www.telenor.no/a/safe-id-detail/surveillance',
  'https://www.telenor.no/a/order-subscription',
  'https://www.telenor.no/a/change-subscription/{msisdn}',
  'https://www.telenor.no/a/subscriber-verification-result/',
  'https://www.telenor.no/a/vipps-receiver',
  'https://www.telenor.no/a/subscription-summary/{msisdn}',
  'https://www.telenor.no/a/goodies',
  'https://www.telenor.no/a/top-up/{msisdn}',
  'https://www.telenor.no/a/overview',
  'https://www.telenor.no/a/bestill-forsikring-samlet',
  'https://www.telenor.no/a/external-goodie-order-callback/{msisdn}',
  'https://www.telenor.no/a/family-bonus/invitation',
  'https://www.telenor.no/a/invoice/{invoiceid}/details',
  'https://www.telenor.no/a/verify-contact-info',
  'https://www.telenor.no/a/family',
  'https://www.telenor.no/a/shop',
  'https://www.telenor.no/a/shop/order-subscription',
  'https://www.telenor.no/a/shop/change-subscription/{msisdn}/',
  'https://www.telenor.no/a/upsale?subject={msisdn}&poid={newpoid}',
];

export default withAuthenticator(ToolsPage);
