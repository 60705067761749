import { Flex, Button, Card, useBreakpointValue } from '@aws-amplify/ui-react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Logo } from './Logo';
import React, { useCallback } from 'react';
import { AuthUser, signOut } from 'aws-amplify/auth';

export const TopBar: React.FC<{ authUser?: AuthUser }> = ({ authUser }) => {
  const navigate = useNavigate();
  const onPressMenuButton = (name: string) => {
    switch (name) {
      case 'accounts':
        navigate('/accounts');
        break;
      case 'tools':
        navigate('/tools');
        break;
      case 'testconfig':
        navigate('/testconfig');
        break;
      case 'login':
        navigate('/login');
        break;
      case 'home':
        navigate('/');
        break;
      default:
        navigate('/');
        break;
    }
  };

  const logOut = useCallback(async () => {
    navigate('/');
    await signOut();
  }, [navigate]);

  const shouldHideIcons = useBreakpointValue({ base: true, small: true, medium: false }) as boolean;
  return (
    <>
      <Card
        flex={1}
        position={'sticky'}
        top={0}
        left={0}
        right={0}
        style={{ marginBottom: '1rem' }}
        boxShadow={'0px 3px 2px -2px rgba(0, 0, 0, 0.2)'}>
        <Flex flex={1} as="nav" justifyContent={'space-between'}>
          <Logo title="Management" />
          <Flex wrap={'wrap'} direction={'row'} alignItems={'baseline'}>
            <Button
              variation={'link'}
              onClick={() => {
                onPressMenuButton('testconfig');
              }}>
              {shouldHideIcons ? 'TestConfig' : '⚙️ Testconfig'}
            </Button>
            {authUser && (
              <Button
                variation={'link'}
                onClick={() => {
                  onPressMenuButton('tools');
                }}>
                {shouldHideIcons ? 'Tools' : '🛠️ Tools'}
              </Button>
            )}
            {authUser && (
              <Button
                variation={'link'}
                onClick={() => {
                  onPressMenuButton('accounts');
                }}>
                {shouldHideIcons ? 'Accounts' : '🧪 Accounts'}
              </Button>
            )}
            {authUser ? (
              <Button variation={'link'} onClick={() => logOut()}>
                {shouldHideIcons ? 'Logout' : '👋 Logout'}
              </Button>
            ) : (
              <Button variation={'link'} onClick={() => onPressMenuButton('login')}>
                {shouldHideIcons ? 'Login' : '👉 Login'}
              </Button>
            )}
          </Flex>
        </Flex>
      </Card>
      <main>
        <Outlet />
      </main>
    </>
  );
};
