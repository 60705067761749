import { Autocomplete, SelectField } from '@aws-amplify/ui-react';
import { Column } from '@tanstack/react-table';
import { useMemo } from 'react';

export const Filter: React.FC<{
  variant: 'select' | 'text';
  column: Column<any, unknown>;
}> = ({ variant, column }) => {
  const filterValue = column.getFilterValue();

  const sortedUniqueValues = useMemo(
    () =>
      Array.from(new Set(Array.from(column.getFacetedUniqueValues().keys()).filter(Boolean).flat()))
        .sort()
        .slice(0, 5000),
    [column],
  );

  switch (variant) {
    case 'select':
      return (
        <SelectField
          value={filterValue?.toString()}
          onChange={(event) => column.setFilterValue(event.target.value.toString())}
          labelHidden
          label="Filter">
          <option value={undefined}>All</option>
          {sortedUniqueValues.map((aValue, i) => (
            <option key={`${column.columnDef.header}_${i}}`} value={aValue.toString()}>
              {aValue}
            </option>
          ))}
        </SelectField>
      );
    case 'text':
    default:
      return (
        <Autocomplete
          label="Default autocomplete"
          placeholder={column.columnDef.header?.toString()}
          onClear={() => column.setFilterValue('')}
          onSelect={(it) => column.setFilterValue(it.label.toString())}
          onChange={(event) => column.setFilterValue(event.target.value.toString())}
          options={sortedUniqueValues.map((aValue) => {
            return { id: aValue, label: aValue };
          })}
        />
      );
  }
};
