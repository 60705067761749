import { Flex, Heading, Text, withAuthenticator } from '@aws-amplify/ui-react';

const FeatureFlagsPage = () => {
  return (
    <Flex direction="column">
      <Heading level={4}>{'Status of feature flags'}</Heading>
      <Text>{'Work in progress...'}</Text>
    </Flex>
  );
};

export default withAuthenticator(FeatureFlagsPage);
