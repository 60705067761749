import '@aws-amplify/ui-react/styles.css';
import config from './aws-exports';
import { TopBar } from './Components/TopBar';
import { useEffect, useState } from 'react';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { Route, Routes, useNavigate } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import TestAccountsPage from './Pages/TestAccounts/TestAccountsPage';
import FeatureFlagsPage from './Pages/FeatureFlagsPage';
import ToolsPage from './Pages/ToolsPage';
import TestConfigPage from './Pages/TestConfigPage';
import SignInPage from './Pages/SignInPage';
import { Amplify } from 'aws-amplify';
import { AuthUser, getCurrentUser, fetchUserAttributes, FetchUserAttributesOutput } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';

TimeAgo.addDefaultLocale(en);
Amplify.configure(config);

const App: React.FC = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<{ authUser: AuthUser; attributes: FetchUserAttributesOutput }>();

  function getAuthenticatedUser() {
    getCurrentUser()
      .then((aUser) => {
        fetchUserAttributes().then((attributes) => {
          setUser({ authUser: aUser, attributes: attributes });
        });
      })
      .catch((err) => {
        setUser(undefined);
      });
  }

  Hub.listen('auth', (data) => {
    switch (data.payload.event) {
      case 'signedIn':
        getAuthenticatedUser();
        navigate('/');
        break;
      case 'signedOut':
        getAuthenticatedUser();
        break;
      default:
        break;
    }
  });

  useEffect(() => {
    getAuthenticatedUser();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<TopBar authUser={user?.authUser} />}>
        <Route index element={<HomePage authUser={user?.authUser} attributes={user?.attributes} />} />
        <Route path="/accounts/:msisdn?" element={<TestAccountsPage />} />
        <Route path="/login" element={<SignInPage />} />
        <Route path="/flags" element={<FeatureFlagsPage />} />
        <Route path="/tools" element={<ToolsPage />} />
        <Route path="/testconfig" element={<TestConfigPage />} />
      </Route>
    </Routes>
  );
};

export default App;
