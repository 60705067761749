import { ScrollView, withAuthenticator, Tabs } from '@aws-amplify/ui-react';
import { ManageTestAccounts } from './ManageTestAccounts';
import { useParams } from 'react-router-dom';
import { TestAccountsReactTableView } from './TestAccountsReactTableView';

function TestAccountsPage() {
  const params = useParams();
  return (
    <Tabs.Container defaultValue="overview">
      <Tabs.List defaultValue="overview">
        <Tabs.Item value="overview">Overview</Tabs.Item>
        <Tabs.Item value="manage">Manage</Tabs.Item>
      </Tabs.List>
      <Tabs.Panel value="overview">
        <ScrollView>
          <TestAccountsReactTableView highlightMsisdn={params.msisdn} />
        </ScrollView>
      </Tabs.Panel>
      <Tabs.Panel value="manage">
        <ScrollView>
          <ManageTestAccounts />
        </ScrollView>
      </Tabs.Panel>
    </Tabs.Container>
  );
}

export default withAuthenticator(TestAccountsPage);
