/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateTestAccountInput = {
  id?: string | null,
  connectId: string,
  msisdn: string,
  name?: string | null,
  hasFamilyBonus?: boolean | null,
  hasSecretNumber?: boolean | null,
  subscriptionType?: string | null,
  marketType?: string | null,
  postpaid?: boolean | null,
  prepaid?: boolean | null,
  isHybrid?: boolean | null,
  hasGoodies?: boolean | null,
  hasIdTheftInsurance?: boolean | null,
  hasSvindelforsikring?: boolean | null,
  hasCancellableData?: boolean | null,
  canOrderSubscription?: boolean | null,
  hasMPort?: boolean | null,
  hasEsim?: boolean | null,
  hasQRCode?: boolean | null,
  hasSafe?: boolean | null,
  subscriptionFamily?: string | null,
  consumerFamily?: ConsumerFamilyInput | null,
  totalCost?: TotalCostInput | null,
  handsets?: Array< HandsetsInput | null > | null,
  mainSim?: MainSimInput | null,
  dataUsages?: Array< DataUsagesInput | null > | null,
  roles?: Array< string | null > | null,
  roamingStatus?: RoamingStatusInput | null,
  userAge?: number | null,
  discountTypes?: Array< string | null > | null,
  promotedFeature?: string | null,
  invoices?: InvoicesInput | null,
  subscriptionSource?: SubscriptionSource | null,
  newSafeProduct?: string | null,
  userName?: string | null,
  numberOfMobileSubscriptions?: number | null,
  numberOfFixedSubscriptions?: number | null,
  securityProducts?: Array< string | null > | null,
};

export type ConsumerFamilyInput = {
  hasConsumerFamilyDiscount?: boolean | null,
};

export type TotalCostInput = {
  cost?: number | null,
};

export type HandsetsInput = {
  simCard?: SimCardInput | null,
  handset?: HandsetInput | null,
};

export type SimCardInput = {
  msisdn?: string | null,
  type?: string | null,
  network?: string | null,
  name?: string | null,
  isEsim?: boolean | null,
  isGolden?: boolean | null,
  barringStatus?: BarringStatusInput | null,
};

export type BarringStatusInput = {
  barred?: boolean | null,
};

export type HandsetInput = {
  vendor?: string | null,
  model?: string | null,
  image?: ImageInput | null,
  agreement?: AgreementInput | null,
  insurances?: Array< InsuranceInput | null > | null,
};

export type ImageInput = {
  url?: string | null,
};

export type AgreementInput = {
  fromDate?: string | null,
};

export type InsuranceInput = {
  discount?: InsuranceDiscountInput | null,
};

export type InsuranceDiscountInput = {
  name?: string | null,
};

export type MainSimInput = {
  msisdn?: string | null,
  barred?: boolean | null,
};

export type DataUsagesInput = {
  type?: string | null,
  assigned?: number | null,
  consumed?: number | null,
  usageUnit?: string | null,
  counterType?: string | null,
  automaticallyRenewable?: boolean | null,
  action?: string | null,
  hasFocus?: boolean | null,
  dataCircleType?: string | null,
  actionInfo?: ActionInfoInput | null,
};

export type ActionInfoInput = {
  action?: string | null,
};

export type RoamingStatusInput = {
  status?: string | null,
  countryIso?: string | null,
  countryName?: string | null,
  technicalZoneName?: string | null,
  isRoamLikeHomeEnabled?: boolean | null,
  roamingText?: string | null,
};

export type InvoicesInput = {
  vippsReceipts?: Array< VippsReceiptInput | null > | null,
  invoices?: Array< InvoiceInput | null > | null,
  creditMemos?: Array< CreditMemoInput | null > | null,
};

export type VippsReceiptInput = {
  invoiceId?: string | null,
};

export type InvoiceInput = {
  invoiceType?: string | null,
  invoiceId?: string | null,
  status?: string | null,
};

export type CreditMemoInput = {
  invoiceId?: string | null,
  status?: string | null,
};

export enum SubscriptionSource {
  MOBILE = "MOBILE",
  FIXED = "FIXED",
  ACCOUNT = "ACCOUNT",
}


export type ModelTestAccountConditionInput = {
  connectId?: ModelStringInput | null,
  msisdn?: ModelStringInput | null,
  name?: ModelStringInput | null,
  hasFamilyBonus?: ModelBooleanInput | null,
  hasSecretNumber?: ModelBooleanInput | null,
  subscriptionType?: ModelStringInput | null,
  marketType?: ModelStringInput | null,
  postpaid?: ModelBooleanInput | null,
  prepaid?: ModelBooleanInput | null,
  isHybrid?: ModelBooleanInput | null,
  hasGoodies?: ModelBooleanInput | null,
  hasIdTheftInsurance?: ModelBooleanInput | null,
  hasSvindelforsikring?: ModelBooleanInput | null,
  hasCancellableData?: ModelBooleanInput | null,
  canOrderSubscription?: ModelBooleanInput | null,
  hasMPort?: ModelBooleanInput | null,
  hasEsim?: ModelBooleanInput | null,
  hasQRCode?: ModelBooleanInput | null,
  hasSafe?: ModelBooleanInput | null,
  subscriptionFamily?: ModelStringInput | null,
  roles?: ModelStringInput | null,
  userAge?: ModelIntInput | null,
  discountTypes?: ModelStringInput | null,
  promotedFeature?: ModelStringInput | null,
  subscriptionSource?: ModelSubscriptionSourceInput | null,
  newSafeProduct?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  numberOfMobileSubscriptions?: ModelIntInput | null,
  numberOfFixedSubscriptions?: ModelIntInput | null,
  securityProducts?: ModelStringInput | null,
  and?: Array< ModelTestAccountConditionInput | null > | null,
  or?: Array< ModelTestAccountConditionInput | null > | null,
  not?: ModelTestAccountConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelSubscriptionSourceInput = {
  eq?: SubscriptionSource | null,
  ne?: SubscriptionSource | null,
};

export type TestAccount = {
  __typename: "TestAccount",
  id: string,
  connectId: string,
  msisdn: string,
  name?: string | null,
  hasFamilyBonus?: boolean | null,
  hasSecretNumber?: boolean | null,
  subscriptionType?: string | null,
  marketType?: string | null,
  postpaid?: boolean | null,
  prepaid?: boolean | null,
  isHybrid?: boolean | null,
  hasGoodies?: boolean | null,
  hasIdTheftInsurance?: boolean | null,
  hasSvindelforsikring?: boolean | null,
  hasCancellableData?: boolean | null,
  canOrderSubscription?: boolean | null,
  hasMPort?: boolean | null,
  hasEsim?: boolean | null,
  hasQRCode?: boolean | null,
  hasSafe?: boolean | null,
  subscriptionFamily?: string | null,
  consumerFamily?: ConsumerFamily | null,
  totalCost?: TotalCost | null,
  handsets?:  Array<Handsets | null > | null,
  mainSim?: MainSim | null,
  dataUsages?:  Array<DataUsages | null > | null,
  roles?: Array< string | null > | null,
  roamingStatus?: RoamingStatus | null,
  userAge?: number | null,
  discountTypes?: Array< string | null > | null,
  promotedFeature?: string | null,
  invoices?: Invoices | null,
  subscriptionSource?: SubscriptionSource | null,
  newSafeProduct?: string | null,
  userName?: string | null,
  numberOfMobileSubscriptions?: number | null,
  numberOfFixedSubscriptions?: number | null,
  securityProducts?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type ConsumerFamily = {
  __typename: "ConsumerFamily",
  hasConsumerFamilyDiscount?: boolean | null,
};

export type TotalCost = {
  __typename: "TotalCost",
  cost?: number | null,
};

export type Handsets = {
  __typename: "Handsets",
  simCard?: SimCard | null,
  handset?: Handset | null,
};

export type SimCard = {
  __typename: "SimCard",
  msisdn?: string | null,
  type?: string | null,
  network?: string | null,
  name?: string | null,
  isEsim?: boolean | null,
  isGolden?: boolean | null,
  barringStatus?: BarringStatus | null,
};

export type BarringStatus = {
  __typename: "BarringStatus",
  barred?: boolean | null,
};

export type Handset = {
  __typename: "Handset",
  vendor?: string | null,
  model?: string | null,
  image?: Image | null,
  agreement?: Agreement | null,
  insurances?:  Array<Insurance | null > | null,
};

export type Image = {
  __typename: "Image",
  url?: string | null,
};

export type Agreement = {
  __typename: "Agreement",
  fromDate?: string | null,
};

export type Insurance = {
  __typename: "Insurance",
  discount?: InsuranceDiscount | null,
};

export type InsuranceDiscount = {
  __typename: "InsuranceDiscount",
  name?: string | null,
};

export type MainSim = {
  __typename: "MainSim",
  msisdn?: string | null,
  barred?: boolean | null,
};

export type DataUsages = {
  __typename: "DataUsages",
  type?: string | null,
  assigned?: number | null,
  consumed?: number | null,
  usageUnit?: string | null,
  counterType?: string | null,
  automaticallyRenewable?: boolean | null,
  action?: string | null,
  hasFocus?: boolean | null,
  dataCircleType?: string | null,
  actionInfo?: ActionInfo | null,
};

export type ActionInfo = {
  __typename: "ActionInfo",
  action?: string | null,
};

export type RoamingStatus = {
  __typename: "RoamingStatus",
  status?: string | null,
  countryIso?: string | null,
  countryName?: string | null,
  technicalZoneName?: string | null,
  isRoamLikeHomeEnabled?: boolean | null,
  roamingText?: string | null,
};

export type Invoices = {
  __typename: "Invoices",
  vippsReceipts?:  Array<VippsReceipt | null > | null,
  invoices?:  Array<Invoice | null > | null,
  creditMemos?:  Array<CreditMemo | null > | null,
};

export type VippsReceipt = {
  __typename: "VippsReceipt",
  invoiceId?: string | null,
};

export type Invoice = {
  __typename: "Invoice",
  invoiceType?: string | null,
  invoiceId?: string | null,
  status?: string | null,
};

export type CreditMemo = {
  __typename: "CreditMemo",
  invoiceId?: string | null,
  status?: string | null,
};

export type UpdateTestAccountInput = {
  id: string,
  connectId?: string | null,
  msisdn?: string | null,
  name?: string | null,
  hasFamilyBonus?: boolean | null,
  hasSecretNumber?: boolean | null,
  subscriptionType?: string | null,
  marketType?: string | null,
  postpaid?: boolean | null,
  prepaid?: boolean | null,
  isHybrid?: boolean | null,
  hasGoodies?: boolean | null,
  hasIdTheftInsurance?: boolean | null,
  hasSvindelforsikring?: boolean | null,
  hasCancellableData?: boolean | null,
  canOrderSubscription?: boolean | null,
  hasMPort?: boolean | null,
  hasEsim?: boolean | null,
  hasQRCode?: boolean | null,
  hasSafe?: boolean | null,
  subscriptionFamily?: string | null,
  consumerFamily?: ConsumerFamilyInput | null,
  totalCost?: TotalCostInput | null,
  handsets?: Array< HandsetsInput | null > | null,
  mainSim?: MainSimInput | null,
  dataUsages?: Array< DataUsagesInput | null > | null,
  roles?: Array< string | null > | null,
  roamingStatus?: RoamingStatusInput | null,
  userAge?: number | null,
  discountTypes?: Array< string | null > | null,
  promotedFeature?: string | null,
  invoices?: InvoicesInput | null,
  subscriptionSource?: SubscriptionSource | null,
  newSafeProduct?: string | null,
  userName?: string | null,
  numberOfMobileSubscriptions?: number | null,
  numberOfFixedSubscriptions?: number | null,
  securityProducts?: Array< string | null > | null,
};

export type DeleteTestAccountInput = {
  id: string,
};

export type CreateConnectIdLoginInput = {
  id?: string | null,
  connectId: string,
  refreshToken?: string | null,
  accessToken?: string | null,
  pkce?: string | null,
  scope?: string | null,
  expiryDate?: string | null,
  state: LoginState,
};

export enum LoginState {
  NEW = "NEW",
  DISABLED = "DISABLED",
  IN_PROGRESS = "IN_PROGRESS",
  ACTIVE = "ACTIVE",
  FAILED = "FAILED",
  EXPIRED = "EXPIRED",
}


export type ModelConnectIdLoginConditionInput = {
  connectId?: ModelStringInput | null,
  refreshToken?: ModelStringInput | null,
  accessToken?: ModelStringInput | null,
  pkce?: ModelStringInput | null,
  scope?: ModelStringInput | null,
  expiryDate?: ModelStringInput | null,
  state?: ModelLoginStateInput | null,
  and?: Array< ModelConnectIdLoginConditionInput | null > | null,
  or?: Array< ModelConnectIdLoginConditionInput | null > | null,
  not?: ModelConnectIdLoginConditionInput | null,
};

export type ModelLoginStateInput = {
  eq?: LoginState | null,
  ne?: LoginState | null,
};

export type ConnectIdLogin = {
  __typename: "ConnectIdLogin",
  id: string,
  connectId: string,
  refreshToken?: string | null,
  accessToken?: string | null,
  pkce?: string | null,
  scope?: string | null,
  expiryDate?: string | null,
  state: LoginState,
  createdAt: string,
  updatedAt: string,
};

export type UpdateConnectIdLoginInput = {
  id: string,
  connectId?: string | null,
  refreshToken?: string | null,
  accessToken?: string | null,
  pkce?: string | null,
  scope?: string | null,
  expiryDate?: string | null,
  state?: LoginState | null,
};

export type DeleteConnectIdLoginInput = {
  id: string,
};

export type ModelTestAccountFilterInput = {
  id?: ModelIDInput | null,
  connectId?: ModelStringInput | null,
  msisdn?: ModelStringInput | null,
  name?: ModelStringInput | null,
  hasFamilyBonus?: ModelBooleanInput | null,
  hasSecretNumber?: ModelBooleanInput | null,
  subscriptionType?: ModelStringInput | null,
  marketType?: ModelStringInput | null,
  postpaid?: ModelBooleanInput | null,
  prepaid?: ModelBooleanInput | null,
  isHybrid?: ModelBooleanInput | null,
  hasGoodies?: ModelBooleanInput | null,
  hasIdTheftInsurance?: ModelBooleanInput | null,
  hasSvindelforsikring?: ModelBooleanInput | null,
  hasCancellableData?: ModelBooleanInput | null,
  canOrderSubscription?: ModelBooleanInput | null,
  hasMPort?: ModelBooleanInput | null,
  hasEsim?: ModelBooleanInput | null,
  hasQRCode?: ModelBooleanInput | null,
  hasSafe?: ModelBooleanInput | null,
  subscriptionFamily?: ModelStringInput | null,
  roles?: ModelStringInput | null,
  userAge?: ModelIntInput | null,
  discountTypes?: ModelStringInput | null,
  promotedFeature?: ModelStringInput | null,
  subscriptionSource?: ModelSubscriptionSourceInput | null,
  newSafeProduct?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  numberOfMobileSubscriptions?: ModelIntInput | null,
  numberOfFixedSubscriptions?: ModelIntInput | null,
  securityProducts?: ModelStringInput | null,
  and?: Array< ModelTestAccountFilterInput | null > | null,
  or?: Array< ModelTestAccountFilterInput | null > | null,
  not?: ModelTestAccountFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelTestAccountConnection = {
  __typename: "ModelTestAccountConnection",
  items:  Array<TestAccount | null >,
  nextToken?: string | null,
};

export type ModelConnectIdLoginFilterInput = {
  id?: ModelIDInput | null,
  connectId?: ModelStringInput | null,
  refreshToken?: ModelStringInput | null,
  accessToken?: ModelStringInput | null,
  pkce?: ModelStringInput | null,
  scope?: ModelStringInput | null,
  expiryDate?: ModelStringInput | null,
  state?: ModelLoginStateInput | null,
  and?: Array< ModelConnectIdLoginFilterInput | null > | null,
  or?: Array< ModelConnectIdLoginFilterInput | null > | null,
  not?: ModelConnectIdLoginFilterInput | null,
};

export type ModelConnectIdLoginConnection = {
  __typename: "ModelConnectIdLoginConnection",
  items:  Array<ConnectIdLogin | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionTestAccountFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  connectId?: ModelSubscriptionStringInput | null,
  msisdn?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  hasFamilyBonus?: ModelSubscriptionBooleanInput | null,
  hasSecretNumber?: ModelSubscriptionBooleanInput | null,
  subscriptionType?: ModelSubscriptionStringInput | null,
  marketType?: ModelSubscriptionStringInput | null,
  postpaid?: ModelSubscriptionBooleanInput | null,
  prepaid?: ModelSubscriptionBooleanInput | null,
  isHybrid?: ModelSubscriptionBooleanInput | null,
  hasGoodies?: ModelSubscriptionBooleanInput | null,
  hasIdTheftInsurance?: ModelSubscriptionBooleanInput | null,
  hasSvindelforsikring?: ModelSubscriptionBooleanInput | null,
  hasCancellableData?: ModelSubscriptionBooleanInput | null,
  canOrderSubscription?: ModelSubscriptionBooleanInput | null,
  hasMPort?: ModelSubscriptionBooleanInput | null,
  hasEsim?: ModelSubscriptionBooleanInput | null,
  hasQRCode?: ModelSubscriptionBooleanInput | null,
  hasSafe?: ModelSubscriptionBooleanInput | null,
  subscriptionFamily?: ModelSubscriptionStringInput | null,
  roles?: ModelSubscriptionStringInput | null,
  userAge?: ModelSubscriptionIntInput | null,
  discountTypes?: ModelSubscriptionStringInput | null,
  promotedFeature?: ModelSubscriptionStringInput | null,
  subscriptionSource?: ModelSubscriptionStringInput | null,
  newSafeProduct?: ModelSubscriptionStringInput | null,
  userName?: ModelSubscriptionStringInput | null,
  numberOfMobileSubscriptions?: ModelSubscriptionIntInput | null,
  numberOfFixedSubscriptions?: ModelSubscriptionIntInput | null,
  securityProducts?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTestAccountFilterInput | null > | null,
  or?: Array< ModelSubscriptionTestAccountFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionConnectIdLoginFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  connectId?: ModelSubscriptionStringInput | null,
  refreshToken?: ModelSubscriptionStringInput | null,
  accessToken?: ModelSubscriptionStringInput | null,
  pkce?: ModelSubscriptionStringInput | null,
  scope?: ModelSubscriptionStringInput | null,
  expiryDate?: ModelSubscriptionStringInput | null,
  state?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionConnectIdLoginFilterInput | null > | null,
  or?: Array< ModelSubscriptionConnectIdLoginFilterInput | null > | null,
};

export type CreateTestAccountMutationVariables = {
  input: CreateTestAccountInput,
  condition?: ModelTestAccountConditionInput | null,
};

export type CreateTestAccountMutation = {
  createTestAccount?:  {
    __typename: "TestAccount",
    id: string,
    connectId: string,
    msisdn: string,
    name?: string | null,
    hasFamilyBonus?: boolean | null,
    hasSecretNumber?: boolean | null,
    subscriptionType?: string | null,
    marketType?: string | null,
    postpaid?: boolean | null,
    prepaid?: boolean | null,
    isHybrid?: boolean | null,
    hasGoodies?: boolean | null,
    hasIdTheftInsurance?: boolean | null,
    hasSvindelforsikring?: boolean | null,
    hasCancellableData?: boolean | null,
    canOrderSubscription?: boolean | null,
    hasMPort?: boolean | null,
    hasEsim?: boolean | null,
    hasQRCode?: boolean | null,
    hasSafe?: boolean | null,
    subscriptionFamily?: string | null,
    consumerFamily?:  {
      __typename: "ConsumerFamily",
      hasConsumerFamilyDiscount?: boolean | null,
    } | null,
    totalCost?:  {
      __typename: "TotalCost",
      cost?: number | null,
    } | null,
    handsets?:  Array< {
      __typename: "Handsets",
      simCard?:  {
        __typename: "SimCard",
        msisdn?: string | null,
        type?: string | null,
        network?: string | null,
        name?: string | null,
        isEsim?: boolean | null,
        isGolden?: boolean | null,
        barringStatus?:  {
          __typename: "BarringStatus",
          barred?: boolean | null,
        } | null,
      } | null,
      handset?:  {
        __typename: "Handset",
        vendor?: string | null,
        model?: string | null,
        image?:  {
          __typename: "Image",
          url?: string | null,
        } | null,
        agreement?:  {
          __typename: "Agreement",
          fromDate?: string | null,
        } | null,
        insurances?:  Array< {
          __typename: "Insurance",
          discount?:  {
            __typename: "InsuranceDiscount",
            name?: string | null,
          } | null,
        } | null > | null,
      } | null,
    } | null > | null,
    mainSim?:  {
      __typename: "MainSim",
      msisdn?: string | null,
      barred?: boolean | null,
    } | null,
    dataUsages?:  Array< {
      __typename: "DataUsages",
      type?: string | null,
      assigned?: number | null,
      consumed?: number | null,
      usageUnit?: string | null,
      counterType?: string | null,
      automaticallyRenewable?: boolean | null,
      action?: string | null,
      hasFocus?: boolean | null,
      dataCircleType?: string | null,
      actionInfo?:  {
        __typename: "ActionInfo",
        action?: string | null,
      } | null,
    } | null > | null,
    roles?: Array< string | null > | null,
    roamingStatus?:  {
      __typename: "RoamingStatus",
      status?: string | null,
      countryIso?: string | null,
      countryName?: string | null,
      technicalZoneName?: string | null,
      isRoamLikeHomeEnabled?: boolean | null,
      roamingText?: string | null,
    } | null,
    userAge?: number | null,
    discountTypes?: Array< string | null > | null,
    promotedFeature?: string | null,
    invoices?:  {
      __typename: "Invoices",
      vippsReceipts?:  Array< {
        __typename: "VippsReceipt",
        invoiceId?: string | null,
      } | null > | null,
      invoices?:  Array< {
        __typename: "Invoice",
        invoiceType?: string | null,
        invoiceId?: string | null,
        status?: string | null,
      } | null > | null,
      creditMemos?:  Array< {
        __typename: "CreditMemo",
        invoiceId?: string | null,
        status?: string | null,
      } | null > | null,
    } | null,
    subscriptionSource?: SubscriptionSource | null,
    newSafeProduct?: string | null,
    userName?: string | null,
    numberOfMobileSubscriptions?: number | null,
    numberOfFixedSubscriptions?: number | null,
    securityProducts?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTestAccountMutationVariables = {
  input: UpdateTestAccountInput,
  condition?: ModelTestAccountConditionInput | null,
};

export type UpdateTestAccountMutation = {
  updateTestAccount?:  {
    __typename: "TestAccount",
    id: string,
    connectId: string,
    msisdn: string,
    name?: string | null,
    hasFamilyBonus?: boolean | null,
    hasSecretNumber?: boolean | null,
    subscriptionType?: string | null,
    marketType?: string | null,
    postpaid?: boolean | null,
    prepaid?: boolean | null,
    isHybrid?: boolean | null,
    hasGoodies?: boolean | null,
    hasIdTheftInsurance?: boolean | null,
    hasSvindelforsikring?: boolean | null,
    hasCancellableData?: boolean | null,
    canOrderSubscription?: boolean | null,
    hasMPort?: boolean | null,
    hasEsim?: boolean | null,
    hasQRCode?: boolean | null,
    hasSafe?: boolean | null,
    subscriptionFamily?: string | null,
    consumerFamily?:  {
      __typename: "ConsumerFamily",
      hasConsumerFamilyDiscount?: boolean | null,
    } | null,
    totalCost?:  {
      __typename: "TotalCost",
      cost?: number | null,
    } | null,
    handsets?:  Array< {
      __typename: "Handsets",
      simCard?:  {
        __typename: "SimCard",
        msisdn?: string | null,
        type?: string | null,
        network?: string | null,
        name?: string | null,
        isEsim?: boolean | null,
        isGolden?: boolean | null,
        barringStatus?:  {
          __typename: "BarringStatus",
          barred?: boolean | null,
        } | null,
      } | null,
      handset?:  {
        __typename: "Handset",
        vendor?: string | null,
        model?: string | null,
        image?:  {
          __typename: "Image",
          url?: string | null,
        } | null,
        agreement?:  {
          __typename: "Agreement",
          fromDate?: string | null,
        } | null,
        insurances?:  Array< {
          __typename: "Insurance",
          discount?:  {
            __typename: "InsuranceDiscount",
            name?: string | null,
          } | null,
        } | null > | null,
      } | null,
    } | null > | null,
    mainSim?:  {
      __typename: "MainSim",
      msisdn?: string | null,
      barred?: boolean | null,
    } | null,
    dataUsages?:  Array< {
      __typename: "DataUsages",
      type?: string | null,
      assigned?: number | null,
      consumed?: number | null,
      usageUnit?: string | null,
      counterType?: string | null,
      automaticallyRenewable?: boolean | null,
      action?: string | null,
      hasFocus?: boolean | null,
      dataCircleType?: string | null,
      actionInfo?:  {
        __typename: "ActionInfo",
        action?: string | null,
      } | null,
    } | null > | null,
    roles?: Array< string | null > | null,
    roamingStatus?:  {
      __typename: "RoamingStatus",
      status?: string | null,
      countryIso?: string | null,
      countryName?: string | null,
      technicalZoneName?: string | null,
      isRoamLikeHomeEnabled?: boolean | null,
      roamingText?: string | null,
    } | null,
    userAge?: number | null,
    discountTypes?: Array< string | null > | null,
    promotedFeature?: string | null,
    invoices?:  {
      __typename: "Invoices",
      vippsReceipts?:  Array< {
        __typename: "VippsReceipt",
        invoiceId?: string | null,
      } | null > | null,
      invoices?:  Array< {
        __typename: "Invoice",
        invoiceType?: string | null,
        invoiceId?: string | null,
        status?: string | null,
      } | null > | null,
      creditMemos?:  Array< {
        __typename: "CreditMemo",
        invoiceId?: string | null,
        status?: string | null,
      } | null > | null,
    } | null,
    subscriptionSource?: SubscriptionSource | null,
    newSafeProduct?: string | null,
    userName?: string | null,
    numberOfMobileSubscriptions?: number | null,
    numberOfFixedSubscriptions?: number | null,
    securityProducts?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTestAccountMutationVariables = {
  input: DeleteTestAccountInput,
  condition?: ModelTestAccountConditionInput | null,
};

export type DeleteTestAccountMutation = {
  deleteTestAccount?:  {
    __typename: "TestAccount",
    id: string,
    connectId: string,
    msisdn: string,
    name?: string | null,
    hasFamilyBonus?: boolean | null,
    hasSecretNumber?: boolean | null,
    subscriptionType?: string | null,
    marketType?: string | null,
    postpaid?: boolean | null,
    prepaid?: boolean | null,
    isHybrid?: boolean | null,
    hasGoodies?: boolean | null,
    hasIdTheftInsurance?: boolean | null,
    hasSvindelforsikring?: boolean | null,
    hasCancellableData?: boolean | null,
    canOrderSubscription?: boolean | null,
    hasMPort?: boolean | null,
    hasEsim?: boolean | null,
    hasQRCode?: boolean | null,
    hasSafe?: boolean | null,
    subscriptionFamily?: string | null,
    consumerFamily?:  {
      __typename: "ConsumerFamily",
      hasConsumerFamilyDiscount?: boolean | null,
    } | null,
    totalCost?:  {
      __typename: "TotalCost",
      cost?: number | null,
    } | null,
    handsets?:  Array< {
      __typename: "Handsets",
      simCard?:  {
        __typename: "SimCard",
        msisdn?: string | null,
        type?: string | null,
        network?: string | null,
        name?: string | null,
        isEsim?: boolean | null,
        isGolden?: boolean | null,
        barringStatus?:  {
          __typename: "BarringStatus",
          barred?: boolean | null,
        } | null,
      } | null,
      handset?:  {
        __typename: "Handset",
        vendor?: string | null,
        model?: string | null,
        image?:  {
          __typename: "Image",
          url?: string | null,
        } | null,
        agreement?:  {
          __typename: "Agreement",
          fromDate?: string | null,
        } | null,
        insurances?:  Array< {
          __typename: "Insurance",
          discount?:  {
            __typename: "InsuranceDiscount",
            name?: string | null,
          } | null,
        } | null > | null,
      } | null,
    } | null > | null,
    mainSim?:  {
      __typename: "MainSim",
      msisdn?: string | null,
      barred?: boolean | null,
    } | null,
    dataUsages?:  Array< {
      __typename: "DataUsages",
      type?: string | null,
      assigned?: number | null,
      consumed?: number | null,
      usageUnit?: string | null,
      counterType?: string | null,
      automaticallyRenewable?: boolean | null,
      action?: string | null,
      hasFocus?: boolean | null,
      dataCircleType?: string | null,
      actionInfo?:  {
        __typename: "ActionInfo",
        action?: string | null,
      } | null,
    } | null > | null,
    roles?: Array< string | null > | null,
    roamingStatus?:  {
      __typename: "RoamingStatus",
      status?: string | null,
      countryIso?: string | null,
      countryName?: string | null,
      technicalZoneName?: string | null,
      isRoamLikeHomeEnabled?: boolean | null,
      roamingText?: string | null,
    } | null,
    userAge?: number | null,
    discountTypes?: Array< string | null > | null,
    promotedFeature?: string | null,
    invoices?:  {
      __typename: "Invoices",
      vippsReceipts?:  Array< {
        __typename: "VippsReceipt",
        invoiceId?: string | null,
      } | null > | null,
      invoices?:  Array< {
        __typename: "Invoice",
        invoiceType?: string | null,
        invoiceId?: string | null,
        status?: string | null,
      } | null > | null,
      creditMemos?:  Array< {
        __typename: "CreditMemo",
        invoiceId?: string | null,
        status?: string | null,
      } | null > | null,
    } | null,
    subscriptionSource?: SubscriptionSource | null,
    newSafeProduct?: string | null,
    userName?: string | null,
    numberOfMobileSubscriptions?: number | null,
    numberOfFixedSubscriptions?: number | null,
    securityProducts?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateConnectIdLoginMutationVariables = {
  input: CreateConnectIdLoginInput,
  condition?: ModelConnectIdLoginConditionInput | null,
};

export type CreateConnectIdLoginMutation = {
  createConnectIdLogin?:  {
    __typename: "ConnectIdLogin",
    id: string,
    connectId: string,
    refreshToken?: string | null,
    accessToken?: string | null,
    pkce?: string | null,
    scope?: string | null,
    expiryDate?: string | null,
    state: LoginState,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateConnectIdLoginMutationVariables = {
  input: UpdateConnectIdLoginInput,
  condition?: ModelConnectIdLoginConditionInput | null,
};

export type UpdateConnectIdLoginMutation = {
  updateConnectIdLogin?:  {
    __typename: "ConnectIdLogin",
    id: string,
    connectId: string,
    refreshToken?: string | null,
    accessToken?: string | null,
    pkce?: string | null,
    scope?: string | null,
    expiryDate?: string | null,
    state: LoginState,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteConnectIdLoginMutationVariables = {
  input: DeleteConnectIdLoginInput,
  condition?: ModelConnectIdLoginConditionInput | null,
};

export type DeleteConnectIdLoginMutation = {
  deleteConnectIdLogin?:  {
    __typename: "ConnectIdLogin",
    id: string,
    connectId: string,
    refreshToken?: string | null,
    accessToken?: string | null,
    pkce?: string | null,
    scope?: string | null,
    expiryDate?: string | null,
    state: LoginState,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetTestAccountQueryVariables = {
  id: string,
};

export type GetTestAccountQuery = {
  getTestAccount?:  {
    __typename: "TestAccount",
    id: string,
    connectId: string,
    msisdn: string,
    name?: string | null,
    hasFamilyBonus?: boolean | null,
    hasSecretNumber?: boolean | null,
    subscriptionType?: string | null,
    marketType?: string | null,
    postpaid?: boolean | null,
    prepaid?: boolean | null,
    isHybrid?: boolean | null,
    hasGoodies?: boolean | null,
    hasIdTheftInsurance?: boolean | null,
    hasSvindelforsikring?: boolean | null,
    hasCancellableData?: boolean | null,
    canOrderSubscription?: boolean | null,
    hasMPort?: boolean | null,
    hasEsim?: boolean | null,
    hasQRCode?: boolean | null,
    hasSafe?: boolean | null,
    subscriptionFamily?: string | null,
    consumerFamily?:  {
      __typename: "ConsumerFamily",
      hasConsumerFamilyDiscount?: boolean | null,
    } | null,
    totalCost?:  {
      __typename: "TotalCost",
      cost?: number | null,
    } | null,
    handsets?:  Array< {
      __typename: "Handsets",
      simCard?:  {
        __typename: "SimCard",
        msisdn?: string | null,
        type?: string | null,
        network?: string | null,
        name?: string | null,
        isEsim?: boolean | null,
        isGolden?: boolean | null,
        barringStatus?:  {
          __typename: "BarringStatus",
          barred?: boolean | null,
        } | null,
      } | null,
      handset?:  {
        __typename: "Handset",
        vendor?: string | null,
        model?: string | null,
        image?:  {
          __typename: "Image",
          url?: string | null,
        } | null,
        agreement?:  {
          __typename: "Agreement",
          fromDate?: string | null,
        } | null,
        insurances?:  Array< {
          __typename: "Insurance",
          discount?:  {
            __typename: "InsuranceDiscount",
            name?: string | null,
          } | null,
        } | null > | null,
      } | null,
    } | null > | null,
    mainSim?:  {
      __typename: "MainSim",
      msisdn?: string | null,
      barred?: boolean | null,
    } | null,
    dataUsages?:  Array< {
      __typename: "DataUsages",
      type?: string | null,
      assigned?: number | null,
      consumed?: number | null,
      usageUnit?: string | null,
      counterType?: string | null,
      automaticallyRenewable?: boolean | null,
      action?: string | null,
      hasFocus?: boolean | null,
      dataCircleType?: string | null,
      actionInfo?:  {
        __typename: "ActionInfo",
        action?: string | null,
      } | null,
    } | null > | null,
    roles?: Array< string | null > | null,
    roamingStatus?:  {
      __typename: "RoamingStatus",
      status?: string | null,
      countryIso?: string | null,
      countryName?: string | null,
      technicalZoneName?: string | null,
      isRoamLikeHomeEnabled?: boolean | null,
      roamingText?: string | null,
    } | null,
    userAge?: number | null,
    discountTypes?: Array< string | null > | null,
    promotedFeature?: string | null,
    invoices?:  {
      __typename: "Invoices",
      vippsReceipts?:  Array< {
        __typename: "VippsReceipt",
        invoiceId?: string | null,
      } | null > | null,
      invoices?:  Array< {
        __typename: "Invoice",
        invoiceType?: string | null,
        invoiceId?: string | null,
        status?: string | null,
      } | null > | null,
      creditMemos?:  Array< {
        __typename: "CreditMemo",
        invoiceId?: string | null,
        status?: string | null,
      } | null > | null,
    } | null,
    subscriptionSource?: SubscriptionSource | null,
    newSafeProduct?: string | null,
    userName?: string | null,
    numberOfMobileSubscriptions?: number | null,
    numberOfFixedSubscriptions?: number | null,
    securityProducts?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTestAccountsQueryVariables = {
  filter?: ModelTestAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTestAccountsQuery = {
  listTestAccounts?:  {
    __typename: "ModelTestAccountConnection",
    items:  Array< {
      __typename: "TestAccount",
      id: string,
      connectId: string,
      msisdn: string,
      name?: string | null,
      hasFamilyBonus?: boolean | null,
      hasSecretNumber?: boolean | null,
      subscriptionType?: string | null,
      marketType?: string | null,
      postpaid?: boolean | null,
      prepaid?: boolean | null,
      isHybrid?: boolean | null,
      hasGoodies?: boolean | null,
      hasIdTheftInsurance?: boolean | null,
      hasSvindelforsikring?: boolean | null,
      hasCancellableData?: boolean | null,
      canOrderSubscription?: boolean | null,
      hasMPort?: boolean | null,
      hasEsim?: boolean | null,
      hasQRCode?: boolean | null,
      hasSafe?: boolean | null,
      subscriptionFamily?: string | null,
      consumerFamily?:  {
        __typename: "ConsumerFamily",
        hasConsumerFamilyDiscount?: boolean | null,
      } | null,
      totalCost?:  {
        __typename: "TotalCost",
        cost?: number | null,
      } | null,
      handsets?:  Array< {
        __typename: "Handsets",
        simCard?:  {
          __typename: "SimCard",
          msisdn?: string | null,
          type?: string | null,
          network?: string | null,
          name?: string | null,
          isEsim?: boolean | null,
          isGolden?: boolean | null,
          barringStatus?:  {
            __typename: "BarringStatus",
            barred?: boolean | null,
          } | null,
        } | null,
        handset?:  {
          __typename: "Handset",
          vendor?: string | null,
          model?: string | null,
          image?:  {
            __typename: "Image",
            url?: string | null,
          } | null,
          agreement?:  {
            __typename: "Agreement",
            fromDate?: string | null,
          } | null,
          insurances?:  Array< {
            __typename: "Insurance",
          } | null > | null,
        } | null,
      } | null > | null,
      mainSim?:  {
        __typename: "MainSim",
        msisdn?: string | null,
        barred?: boolean | null,
      } | null,
      dataUsages?:  Array< {
        __typename: "DataUsages",
        type?: string | null,
        assigned?: number | null,
        consumed?: number | null,
        usageUnit?: string | null,
        counterType?: string | null,
        automaticallyRenewable?: boolean | null,
        action?: string | null,
        hasFocus?: boolean | null,
        dataCircleType?: string | null,
        actionInfo?:  {
          __typename: "ActionInfo",
          action?: string | null,
        } | null,
      } | null > | null,
      roles?: Array< string | null > | null,
      roamingStatus?:  {
        __typename: "RoamingStatus",
        status?: string | null,
        countryIso?: string | null,
        countryName?: string | null,
        technicalZoneName?: string | null,
        isRoamLikeHomeEnabled?: boolean | null,
        roamingText?: string | null,
      } | null,
      userAge?: number | null,
      discountTypes?: Array< string | null > | null,
      promotedFeature?: string | null,
      invoices?:  {
        __typename: "Invoices",
        vippsReceipts?:  Array< {
          __typename: "VippsReceipt",
          invoiceId?: string | null,
        } | null > | null,
        invoices?:  Array< {
          __typename: "Invoice",
          invoiceType?: string | null,
          invoiceId?: string | null,
          status?: string | null,
        } | null > | null,
        creditMemos?:  Array< {
          __typename: "CreditMemo",
          invoiceId?: string | null,
          status?: string | null,
        } | null > | null,
      } | null,
      subscriptionSource?: SubscriptionSource | null,
      newSafeProduct?: string | null,
      userName?: string | null,
      numberOfMobileSubscriptions?: number | null,
      numberOfFixedSubscriptions?: number | null,
      securityProducts?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetConnectIdLoginQueryVariables = {
  id: string,
};

export type GetConnectIdLoginQuery = {
  getConnectIdLogin?:  {
    __typename: "ConnectIdLogin",
    id: string,
    connectId: string,
    refreshToken?: string | null,
    accessToken?: string | null,
    pkce?: string | null,
    scope?: string | null,
    expiryDate?: string | null,
    state: LoginState,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListConnectIdLoginsQueryVariables = {
  filter?: ModelConnectIdLoginFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConnectIdLoginsQuery = {
  listConnectIdLogins?:  {
    __typename: "ModelConnectIdLoginConnection",
    items:  Array< {
      __typename: "ConnectIdLogin",
      id: string,
      connectId: string,
      refreshToken?: string | null,
      accessToken?: string | null,
      pkce?: string | null,
      scope?: string | null,
      expiryDate?: string | null,
      state: LoginState,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateTestAccountSubscriptionVariables = {
  filter?: ModelSubscriptionTestAccountFilterInput | null,
};

export type OnCreateTestAccountSubscription = {
  onCreateTestAccount?:  {
    __typename: "TestAccount",
    id: string,
    connectId: string,
    msisdn: string,
    name?: string | null,
    hasFamilyBonus?: boolean | null,
    hasSecretNumber?: boolean | null,
    subscriptionType?: string | null,
    marketType?: string | null,
    postpaid?: boolean | null,
    prepaid?: boolean | null,
    isHybrid?: boolean | null,
    hasGoodies?: boolean | null,
    hasIdTheftInsurance?: boolean | null,
    hasSvindelforsikring?: boolean | null,
    hasCancellableData?: boolean | null,
    canOrderSubscription?: boolean | null,
    hasMPort?: boolean | null,
    hasEsim?: boolean | null,
    hasQRCode?: boolean | null,
    hasSafe?: boolean | null,
    subscriptionFamily?: string | null,
    consumerFamily?:  {
      __typename: "ConsumerFamily",
      hasConsumerFamilyDiscount?: boolean | null,
    } | null,
    totalCost?:  {
      __typename: "TotalCost",
      cost?: number | null,
    } | null,
    handsets?:  Array< {
      __typename: "Handsets",
      simCard?:  {
        __typename: "SimCard",
        msisdn?: string | null,
        type?: string | null,
        network?: string | null,
        name?: string | null,
        isEsim?: boolean | null,
        isGolden?: boolean | null,
        barringStatus?:  {
          __typename: "BarringStatus",
          barred?: boolean | null,
        } | null,
      } | null,
      handset?:  {
        __typename: "Handset",
        vendor?: string | null,
        model?: string | null,
        image?:  {
          __typename: "Image",
          url?: string | null,
        } | null,
        agreement?:  {
          __typename: "Agreement",
          fromDate?: string | null,
        } | null,
        insurances?:  Array< {
          __typename: "Insurance",
          discount?:  {
            __typename: "InsuranceDiscount",
            name?: string | null,
          } | null,
        } | null > | null,
      } | null,
    } | null > | null,
    mainSim?:  {
      __typename: "MainSim",
      msisdn?: string | null,
      barred?: boolean | null,
    } | null,
    dataUsages?:  Array< {
      __typename: "DataUsages",
      type?: string | null,
      assigned?: number | null,
      consumed?: number | null,
      usageUnit?: string | null,
      counterType?: string | null,
      automaticallyRenewable?: boolean | null,
      action?: string | null,
      hasFocus?: boolean | null,
      dataCircleType?: string | null,
      actionInfo?:  {
        __typename: "ActionInfo",
        action?: string | null,
      } | null,
    } | null > | null,
    roles?: Array< string | null > | null,
    roamingStatus?:  {
      __typename: "RoamingStatus",
      status?: string | null,
      countryIso?: string | null,
      countryName?: string | null,
      technicalZoneName?: string | null,
      isRoamLikeHomeEnabled?: boolean | null,
      roamingText?: string | null,
    } | null,
    userAge?: number | null,
    discountTypes?: Array< string | null > | null,
    promotedFeature?: string | null,
    invoices?:  {
      __typename: "Invoices",
      vippsReceipts?:  Array< {
        __typename: "VippsReceipt",
        invoiceId?: string | null,
      } | null > | null,
      invoices?:  Array< {
        __typename: "Invoice",
        invoiceType?: string | null,
        invoiceId?: string | null,
        status?: string | null,
      } | null > | null,
      creditMemos?:  Array< {
        __typename: "CreditMemo",
        invoiceId?: string | null,
        status?: string | null,
      } | null > | null,
    } | null,
    subscriptionSource?: SubscriptionSource | null,
    newSafeProduct?: string | null,
    userName?: string | null,
    numberOfMobileSubscriptions?: number | null,
    numberOfFixedSubscriptions?: number | null,
    securityProducts?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTestAccountSubscriptionVariables = {
  filter?: ModelSubscriptionTestAccountFilterInput | null,
};

export type OnUpdateTestAccountSubscription = {
  onUpdateTestAccount?:  {
    __typename: "TestAccount",
    id: string,
    connectId: string,
    msisdn: string,
    name?: string | null,
    hasFamilyBonus?: boolean | null,
    hasSecretNumber?: boolean | null,
    subscriptionType?: string | null,
    marketType?: string | null,
    postpaid?: boolean | null,
    prepaid?: boolean | null,
    isHybrid?: boolean | null,
    hasGoodies?: boolean | null,
    hasIdTheftInsurance?: boolean | null,
    hasSvindelforsikring?: boolean | null,
    hasCancellableData?: boolean | null,
    canOrderSubscription?: boolean | null,
    hasMPort?: boolean | null,
    hasEsim?: boolean | null,
    hasQRCode?: boolean | null,
    hasSafe?: boolean | null,
    subscriptionFamily?: string | null,
    consumerFamily?:  {
      __typename: "ConsumerFamily",
      hasConsumerFamilyDiscount?: boolean | null,
    } | null,
    totalCost?:  {
      __typename: "TotalCost",
      cost?: number | null,
    } | null,
    handsets?:  Array< {
      __typename: "Handsets",
      simCard?:  {
        __typename: "SimCard",
        msisdn?: string | null,
        type?: string | null,
        network?: string | null,
        name?: string | null,
        isEsim?: boolean | null,
        isGolden?: boolean | null,
        barringStatus?:  {
          __typename: "BarringStatus",
          barred?: boolean | null,
        } | null,
      } | null,
      handset?:  {
        __typename: "Handset",
        vendor?: string | null,
        model?: string | null,
        image?:  {
          __typename: "Image",
          url?: string | null,
        } | null,
        agreement?:  {
          __typename: "Agreement",
          fromDate?: string | null,
        } | null,
        insurances?:  Array< {
          __typename: "Insurance",
          discount?:  {
            __typename: "InsuranceDiscount",
            name?: string | null,
          } | null,
        } | null > | null,
      } | null,
    } | null > | null,
    mainSim?:  {
      __typename: "MainSim",
      msisdn?: string | null,
      barred?: boolean | null,
    } | null,
    dataUsages?:  Array< {
      __typename: "DataUsages",
      type?: string | null,
      assigned?: number | null,
      consumed?: number | null,
      usageUnit?: string | null,
      counterType?: string | null,
      automaticallyRenewable?: boolean | null,
      action?: string | null,
      hasFocus?: boolean | null,
      dataCircleType?: string | null,
      actionInfo?:  {
        __typename: "ActionInfo",
        action?: string | null,
      } | null,
    } | null > | null,
    roles?: Array< string | null > | null,
    roamingStatus?:  {
      __typename: "RoamingStatus",
      status?: string | null,
      countryIso?: string | null,
      countryName?: string | null,
      technicalZoneName?: string | null,
      isRoamLikeHomeEnabled?: boolean | null,
      roamingText?: string | null,
    } | null,
    userAge?: number | null,
    discountTypes?: Array< string | null > | null,
    promotedFeature?: string | null,
    invoices?:  {
      __typename: "Invoices",
      vippsReceipts?:  Array< {
        __typename: "VippsReceipt",
        invoiceId?: string | null,
      } | null > | null,
      invoices?:  Array< {
        __typename: "Invoice",
        invoiceType?: string | null,
        invoiceId?: string | null,
        status?: string | null,
      } | null > | null,
      creditMemos?:  Array< {
        __typename: "CreditMemo",
        invoiceId?: string | null,
        status?: string | null,
      } | null > | null,
    } | null,
    subscriptionSource?: SubscriptionSource | null,
    newSafeProduct?: string | null,
    userName?: string | null,
    numberOfMobileSubscriptions?: number | null,
    numberOfFixedSubscriptions?: number | null,
    securityProducts?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTestAccountSubscriptionVariables = {
  filter?: ModelSubscriptionTestAccountFilterInput | null,
};

export type OnDeleteTestAccountSubscription = {
  onDeleteTestAccount?:  {
    __typename: "TestAccount",
    id: string,
    connectId: string,
    msisdn: string,
    name?: string | null,
    hasFamilyBonus?: boolean | null,
    hasSecretNumber?: boolean | null,
    subscriptionType?: string | null,
    marketType?: string | null,
    postpaid?: boolean | null,
    prepaid?: boolean | null,
    isHybrid?: boolean | null,
    hasGoodies?: boolean | null,
    hasIdTheftInsurance?: boolean | null,
    hasSvindelforsikring?: boolean | null,
    hasCancellableData?: boolean | null,
    canOrderSubscription?: boolean | null,
    hasMPort?: boolean | null,
    hasEsim?: boolean | null,
    hasQRCode?: boolean | null,
    hasSafe?: boolean | null,
    subscriptionFamily?: string | null,
    consumerFamily?:  {
      __typename: "ConsumerFamily",
      hasConsumerFamilyDiscount?: boolean | null,
    } | null,
    totalCost?:  {
      __typename: "TotalCost",
      cost?: number | null,
    } | null,
    handsets?:  Array< {
      __typename: "Handsets",
      simCard?:  {
        __typename: "SimCard",
        msisdn?: string | null,
        type?: string | null,
        network?: string | null,
        name?: string | null,
        isEsim?: boolean | null,
        isGolden?: boolean | null,
        barringStatus?:  {
          __typename: "BarringStatus",
          barred?: boolean | null,
        } | null,
      } | null,
      handset?:  {
        __typename: "Handset",
        vendor?: string | null,
        model?: string | null,
        image?:  {
          __typename: "Image",
          url?: string | null,
        } | null,
        agreement?:  {
          __typename: "Agreement",
          fromDate?: string | null,
        } | null,
        insurances?:  Array< {
          __typename: "Insurance",
          discount?:  {
            __typename: "InsuranceDiscount",
            name?: string | null,
          } | null,
        } | null > | null,
      } | null,
    } | null > | null,
    mainSim?:  {
      __typename: "MainSim",
      msisdn?: string | null,
      barred?: boolean | null,
    } | null,
    dataUsages?:  Array< {
      __typename: "DataUsages",
      type?: string | null,
      assigned?: number | null,
      consumed?: number | null,
      usageUnit?: string | null,
      counterType?: string | null,
      automaticallyRenewable?: boolean | null,
      action?: string | null,
      hasFocus?: boolean | null,
      dataCircleType?: string | null,
      actionInfo?:  {
        __typename: "ActionInfo",
        action?: string | null,
      } | null,
    } | null > | null,
    roles?: Array< string | null > | null,
    roamingStatus?:  {
      __typename: "RoamingStatus",
      status?: string | null,
      countryIso?: string | null,
      countryName?: string | null,
      technicalZoneName?: string | null,
      isRoamLikeHomeEnabled?: boolean | null,
      roamingText?: string | null,
    } | null,
    userAge?: number | null,
    discountTypes?: Array< string | null > | null,
    promotedFeature?: string | null,
    invoices?:  {
      __typename: "Invoices",
      vippsReceipts?:  Array< {
        __typename: "VippsReceipt",
        invoiceId?: string | null,
      } | null > | null,
      invoices?:  Array< {
        __typename: "Invoice",
        invoiceType?: string | null,
        invoiceId?: string | null,
        status?: string | null,
      } | null > | null,
      creditMemos?:  Array< {
        __typename: "CreditMemo",
        invoiceId?: string | null,
        status?: string | null,
      } | null > | null,
    } | null,
    subscriptionSource?: SubscriptionSource | null,
    newSafeProduct?: string | null,
    userName?: string | null,
    numberOfMobileSubscriptions?: number | null,
    numberOfFixedSubscriptions?: number | null,
    securityProducts?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateConnectIdLoginSubscriptionVariables = {
  filter?: ModelSubscriptionConnectIdLoginFilterInput | null,
};

export type OnCreateConnectIdLoginSubscription = {
  onCreateConnectIdLogin?:  {
    __typename: "ConnectIdLogin",
    id: string,
    connectId: string,
    refreshToken?: string | null,
    accessToken?: string | null,
    pkce?: string | null,
    scope?: string | null,
    expiryDate?: string | null,
    state: LoginState,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateConnectIdLoginSubscriptionVariables = {
  filter?: ModelSubscriptionConnectIdLoginFilterInput | null,
};

export type OnUpdateConnectIdLoginSubscription = {
  onUpdateConnectIdLogin?:  {
    __typename: "ConnectIdLogin",
    id: string,
    connectId: string,
    refreshToken?: string | null,
    accessToken?: string | null,
    pkce?: string | null,
    scope?: string | null,
    expiryDate?: string | null,
    state: LoginState,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteConnectIdLoginSubscriptionVariables = {
  filter?: ModelSubscriptionConnectIdLoginFilterInput | null,
};

export type OnDeleteConnectIdLoginSubscription = {
  onDeleteConnectIdLogin?:  {
    __typename: "ConnectIdLogin",
    id: string,
    connectId: string,
    refreshToken?: string | null,
    accessToken?: string | null,
    pkce?: string | null,
    scope?: string | null,
    expiryDate?: string | null,
    state: LoginState,
    createdAt: string,
    updatedAt: string,
  } | null,
};
