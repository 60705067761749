import { Button, Divider, Flex, Heading, Image, Text } from '@aws-amplify/ui-react';
import QRCode from '../assets/qr_code_testconfig_page.png';

function TestConfigPage() {
  return (
    <Flex direction="column">
      <Heading level={4}>{'Activate test config on your device'}</Heading>
      <Divider size="small" />
      <Heading level={5}>{'📱 Test mode'}</Heading>
      <Text>
        {
          "Click the button if you want to enable test mode on the device you're on, or scan the QR code from a device. The app needs to be installed for this to work, and you will be informed that the test config has been received. If that doesn't happen you probably need a newer client."
        }
      </Text>
      <Button
        variation="primary"
        onClick={() =>
          window.open(
            'mitttelenor://testconfig/eyJkZXZNb2RlIjogdHJ1ZSwgImJhY2tlbmRzIjogW3siaWQiOiAiYXRlc3RiYW5raWQiLCAibmFtZSI6ICJCUlVNTStCYW5rSWQiLCAiYW5kcm9pZENsaWVudElkIjogInRubi1taXR0LXRlbGVub3ItbmV4dC1hbmRyb2lkIiwgImlvc0NsaWVudElkIjogInRubi1taXR0LXRlbGVub3ItbmV4dC1pb3MiLCAiYXBpSG9zdCI6ICJodHRwczovL2F0ZXN0LWFwaWd3LnRlbGVub3Iubm8iLCAibG9naW5UeXBlIjogInRlbGVub3JJZFBsdXNUZXN0In0seyJpZCI6ICJhdGVzdCIsICJuYW1lIjogIkJSVU1NIiwgImFwaUhvc3QiOiAiaHR0cHM6Ly9hdGVzdC1hcGlndy50ZWxlbm9yLm5vIiwgImxvZ2luVHlwZSI6ICJ0ZWxlbm9ySWRQbHVzVGVzdCJ9XX0=',
            'self',
          )
        }
        alignSelf={'flex-start'}>
        {'Click here to activate test mode on device'}
      </Button>
      <Image alt={undefined} src={QRCode} width={400} />
    </Flex>
  );
}

export default TestConfigPage;
